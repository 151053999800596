.awool {
  &-img-box {
    padding: 0 2rem;
  }
  &-img {
    object-fit: cover;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("./../../images/awoolplanet_etc01.png");
    height: 22.5rem;
  }
  &-img-2nd {
    object-fit: cover;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("./../../images/awoolplanet_etc02.png");
    height: 22.5rem;
    
    @include respond(xs) { 
      margin-top: 2.5rem;
    }
    @include respond(sm) { 
      margin-top: 2.5rem;
    }
    @include respond(md) { 
      margin-top: 2.5rem;
    }
    @include respond(lg) { 
      margin-top: 2.5rem; 
    }
    @include respond(xl) { 
    }
  }
}
.content {
	position: relative;
	
	&-title {
		span {
      font-size: 4rem;
    }
    font-size: 2.8rem;
    font-weight: 600;
	}
	&-article {
    padding: 2.5rem 1rem;
	}
	&-article-2nd {
		padding: 0.5rem 1rem 2.5rem 1rem;
		
	}
	display: block;

	&-final {
		margin-bottom: 4rem;
	}
}
// MEDIA QUERY MANAGER
/*
0 - 600px:      xs
600 - 900px:    Tablet portrait
900 - 1200px:   Tablet landscape
[1200 - 1800] is where our normal styles apply
1800px + :      Big desktop

$breakpoint arguement choices:
- xs
- sm
- lg
- xl

ORDER: Base + typography > general layout + grid > page layout > components

1em = 16px
*/
@mixin respond($breakpoint) {
  @if $breakpoint == xs {
    @media (max-width: 575.98px) { @content };    //575.98px
  }
  @if $breakpoint == sm {
    @media (min-width: 576px) and (max-width: 767.98px) { @content };     //576px ~ 767.98px
  }
  @if $breakpoint == md {
    @media (min-width: 768px) and (max-width: 1019.99px) { @content };     //768px ~ 991.98px 새로 생성(190128) by KM
  }
  @if $breakpoint == lg {
    @media (min-width: 1020px) and (max-width: 1199.98px) { @content };    //992px ~ 1199.98px
  }
  @if $breakpoint == xl {
    @media (min-width: 1200px) { @content };    //1200px
  }
}

@mixin text($font-size, $font-weight) {
  font-size: $font-size;
  font-weight: $font-weight;
}


@mixin vertical-center() {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
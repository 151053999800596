// Colors
$color-purple: #8b4dff;
$color-green: #5FB0A8;
$color-blue: #219DCB;
$color-pink: #F05333;
$color-red: #C5132D;
$color-green-2: #9CB227;
$color-yellow: #FFD112;

$color-gray: #4a4a4a;
$color-gray-2: #D8D8D8;
$color-black: #000000;
$color-white: #ffffff;

$color-main: $color-black;
$color-drdiary: $color-red;
$color-awool: $color-purple;
$color-mmume: $color-green;
$color-syds-blue: $color-blue;
$color-ariel: $color-pink;
$color-taxi-join: $color-yellow;

// Header Component
$header-height: 68px;



.u-color-main { color: $color-main; }
.u-color-drdiary { color: $color-drdiary; }
.u-color-awool { color: $color-awool; }
.u-color-mmume { color: $color-mmume; }
.u-color-syds { color: $color-syds-blue; }
.u-color-ariel { color: $color-pink; }
.u-color-taxi { color: $color-taxi-join; }

.u-text-center { text-align: center !important; }
.u-text-right { text-align: right !important; }
.u-text-left { text-align: left !important; }

.u-padding-0 { padding: 0 !important; }

.u-padding-left-0 { padding-left: 0 !important; }
.u-padding-right-0 { padding-right: 0 !important; }
.u-padding-top-0 { padding-top: 0 !important; }
.u-padding-bottom-0 { padding-bottom: 0 !important; }

.u-padding-left-1-5 { padding-left: 15px !important; }
.u-padding-right-1-5 { padding-right: 15px !important; }
.u-padding-top-1-5 { padding-top: 15px !important; }
.u-padding-bottom-1-5 { padding-bottom: 15px !important; }

.u-padding-left-1-8 { padding-left: 18px !important; }
.u-padding-right-1-8 { padding-right: 18px !important; }
.u-padding-top-1-8 { padding-top: 18px !important; }
.u-padding-bottom-1-8 { padding-bottom: 18px !important; }

.u-padding-left-2-0 { padding-left: 20px !important; }
.u-padding-right-2-0 { padding-right: 20px !important; }
.u-padding-top-2-0 { padding-top: 20px !important; }
.u-padding-bottom-2-0 { padding-bottom: 20px !important; }

.u-padding-left-2-5 { padding-left: 25px !important; }
.u-padding-right-2-5 { padding-right: 25px !important; }
.u-padding-top-2-5 { padding-top: 25px !important; }
.u-padding-bottom-2-5 { padding-bottom: 25px !important; }

.u-padding-left-3-0 { padding-left: 30px !important; }
.u-padding-right-3-0 { padding-right: 30px !important; }
.u-padding-top-3-0 { padding-top: 30px !important; }
.u-padding-bottom-3-0 { padding-bottom: 30px !important; }

.u-opacity-0 { opacity: 0 !important; }
.u-opacity-0-2 { opacity: 0.2 !important; }
.u-opacity-0-2-xs { 
  @include respond(xs) { 
    opacity: 0.2 !important;
  } 
}
.u-opacity-1 { opacity: 1 !important; }

.u-display-inline-block { display: inline-block !important; }
.u-display-none { display: none !important; }
.u-display-none-xs { 
  @include respond(xs) { 
    display: none !important;
  } 
}

.u-visibility-hidden { visibility: hidden !important; }
.u-visibility-visible { visibility: visible !important; }

.u-hidden-mobile {
  @include respond(xs) { 
    display: none;
  }
}

.u-vertical-center { 
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.u-img-center {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  object-fit: cover;
}

.u-has-error {
  color: #EC5E60;
  font-size: 12px;
}

.u-border-bottom {
  border-bottom: 1.5px solid tint($color-gray, 50%);
}

.u-level-01 {
  color: $color-blue;
  font-size: 1.6rem;
}

.u-level-02 {
  color: #75DAFF;
  font-size: 1.76rem;
}

.u-level-03 {
  color: $color-black;
}

.u-level-04 {
  color: #FF9782;
  font-size: 2.34rem;
}

.u-level-05 {
  color: $color-pink;
  font-size: 2.6rem;
}

.u-color-red {
  color: $color-pink;
}
html {
  // This defines what 1rem is
  // font-size: 62.5%; //1 rem = 10px; 10px/16px = 62.5%
  font-size: 62.5%;

  @include respond(xs) { 
    font-size: 52%;
  }
  @include respond(sm) { 
    font-size: 49%;
  }
  @include respond(md) {
    font-size: 62.5%;
  }
  @include respond(lg) {
    font-size: 67.5%;
  }
  @include respond(xl) {
    font-size: 70.5%;
  }
}

body {
  overflow-x: hidden;
}

p {
  margin: 0;
}

a {
  color: #4a4a4a;
  text-decoration: none;
}

a:hover {
  color: $color-main;
  text-decoration: none;
}

h2 {
  margin-bottom: 0;
}
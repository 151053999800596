.home {
  @include respond(xs) { 
    padding: 1.5rem 1.5rem 0 1.5rem;
  }
  @include respond(sm) { 
    padding: 1.5rem 1.5rem 0 1.5rem;
  }
  @include respond(md) { 
    padding: 1.5rem 1.5rem 0 1.5rem;
  }
  @include respond(lg) { 
    padding: 3rem 3rem 0 3rem;
  }
  @include respond(xl) { 
    padding: 3rem 3rem 0 3rem;
  }

  &_title {
    font-size: 1.8rem;
  }

  &__work {
    cursor: pointer;
  }

  &__resume {
    cursor: pointer;
    text-align: right;
  }

  .border {
    @include respond(xs) { 
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
    }
    @include respond(sm) { 
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
      margin-right: 1.5rem;
    }
    @include respond(md) { 
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
      margin-right: 1.5rem;
    }
    @include respond(lg) { 
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
      margin-right: 3rem;
    }
    @include respond(xl) { 
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
      margin-right: 3rem;
    }
  }

  &-about {
    cursor: pointer;
  }

  &__about {
    @include respond(xs) { 

    }
    @include respond(sm) { 
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
    @include respond(md) { 
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
    @include respond(lg) { 
      padding-left: 3rem;
      padding-right: 3rem;
    }
    @include respond(xl) { 
      padding-left: 3rem;
      padding-right: 3rem;
    }
  }  
  &__content {
    @include respond(xs) { 
    }
    @include respond(sm) { 
      padding-top: 1.5rem;
    }
    @include respond(md) { 
      padding-top: 1.5rem;
    }
    @include respond(lg) { 
      padding-top: 2rem;
    }
    @include respond(xl) { 
      padding-top: 2rem;
    }
  }
}

.vertical-line {
  position: absolute;
  left: 50%;
  width: 100%;
  height: 100vh;
  
  @include respond(xs) { 
    display: none;
  }
  @include respond(sm) { 
    border-left: 1.5px solid $color-gray-2;
  }
  @include respond(md) { 
    border-left: 1.5px solid $color-gray-2;
  }
  @include respond(lg) { 
    border-left: 1.5px solid $color-gray-2;
  }
  @include respond(xl) { 
    border-left: 1.5px solid $color-gray-2;
  }
}

.vertical-line-2 {
  @include respond(xs) {}
  @include respond(sm) { 
    border-left: 1.5px solid $color-gray-2;
  }
  @include respond(md) { 
    border-left: 1.5px solid $color-gray-2;
  }
  @include respond(lg) { 
    border-left: 1.5px solid $color-gray-2;
  }
  @include respond(xl) { 
    border-left: 1.5px solid $color-gray-2;
  }
}
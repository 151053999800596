.about-me {
	&-img-box {
		padding: 0 2rem;
		text-align: left;
	}
	&-img {
    object-fit: cover;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("./../../images/about-me-etc03.jpeg");
		width: 100%;
		max-height: 32rem;
		margin-bottom: 2.5rem;
	}
	&-skill-list {
		text-align: right;
		width: 100%;
	}
}
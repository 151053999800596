.swiper-pagination {
  display: none;
}

.swiper-pagination-bullet-active {
  margin-top: 20px;
  background-color: $color-main;
}

.swiper-button-next {
  @include respond(xs) { 
    display: none;
  }
}

.swiper-button-prev {
  @include respond(xs) { 
    display: none;
  }
}

.slide {
  &-img {
    @include respond(xs) { 
      width: 12.5rem;
    }
    @include respond(sm) { 
      width: 12.5rem;
    }
    @include respond(md) { 
      width: 14rem;
    }
    @include respond(lg) { 
      width: 15rem;
    }
    @include respond(xl) { 
      width: 17rem;
    }
  }
}
[class^='col-'] {
  padding: 0;
  margin: 0;
}

.container-fluid {
  padding: 0;
  margin: 0;
}

.row {
  margin-right: 0;
  margin-left: 0;
}
.work {
	
}

.work-list {
	@include respond(xs) { 
		max-height: 150px;
		transition: max-height 0.2s ease-in;
	}
	
	&__date {
		text-align: right;
		font-size: 1rem;
		padding: 0 1.5rem;
		line-height: initial;
		height: fit-content;
		transform: translateY(0.8rem);
		@include respond(xs) { 
			transform: translateY(3.5px);
		}
		@include respond(sm) { 
			transform: translateY(3.5px);
		}
		@include respond(md) { 
		}
		@include respond(lg) { 
		}
		@include respond(xl) { 
		}
	}
	&__title {
		line-height: initial;
		height: fit-content;
		font-size: 3.2rem;
		font-weight: bold;
	}
}

.dr-diary {
	position: absolute;
	width: 100%;
	z-index: 100;
	&__hover {
		cursor: pointer;
		height: 3.5rem;
		margin-right: 3rem;
		margin-left: 1.5rem;
	}
	&__hover:hover {
		background-color: $color-drdiary;
		opacity: 0.5;
		@include respond(xs) { 
			// opacity: 0;
		}
	}
}

.awool-planet {
	position: absolute;
	width: 100%;
	z-index: 100;
	&__hover {
		cursor: pointer;
		height: 3.5rem;
		margin-right: 3rem;
		margin-left: 1.5rem;
	}
	&__hover:hover {
		background-color: $color-awool;
		opacity: 0.5;
		@include respond(xs) { 
			// opacity: 0;
		}
	}
}

.mmume {
	position: absolute;
	width: 100%;
	z-index: 100;
	&__hover {
		cursor: pointer;
		height: 3.5rem;
		margin-right: 3rem;
		margin-left: 1.5rem;
	}
	&__hover:hover {
		background-color: $color-mmume;
		opacity: 0.5;
		@include respond(xs) { 
			// opacity: 0;
		}
	}
}

.syds {
	position: absolute;
	width: 100%;
	z-index: 100;
	&__hover {
		cursor: pointer;
		height: 3.5rem;
		margin-right: 3rem;
		margin-left: 1.5rem;
	}
	&__hover:hover {
		background-color: $color-syds-blue;
		opacity: 0.5;
		@include respond(xs) { 
			// opacity: 0;
		}
	}
}

.ariel {
	position: absolute;
	width: 100%;
	z-index: 100;
	&__hover {
		cursor: pointer;
		height: 3.5rem;
		margin-right: 3rem;
		margin-left: 1.5rem;
	}
	&__hover:hover {
		background-color: $color-ariel;
		opacity: 0.5;
		@include respond(xs) { 
			// opacity: 0;
		}
	}
}

.taxi-join {
	position: absolute;
	width: 100%;
	z-index: 100;
	&__hover {
		cursor: pointer;
		height: 3.5rem;
		margin-right: 3rem;
		margin-left: 1.5rem;
	}
	&__hover:hover {
		background-color: $color-taxi-join;
		opacity: 0.5;
		@include respond(xs) { 
			// opacity: 0;
		}
	}
}

.menu-close {
	@include respond(xs) { 
		max-height: 0;
		transition: max-height 0.1s ease-out;
		overflow: hidden;
	}
}

.border-close {
	@include respond(xs) { 
		display: none;
	}
}
.mmume {
  &-title {
    span {
      font-size: 4rem;
    }
    font-size: 2.8rem;
    font-weight: 600;
  }
  &-article {
    padding: 2.5rem 1rem;
  }
  &-img-box {
    padding: 0 2rem;
  }
  &-img {
    object-fit: cover;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("./../../images/mmume_product.png");
    height: 28rem;
  }
  &-img-2nd {
    object-fit: cover;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("./../../images/mmume_etc01.png");
    height: 28rem;
    
    @include respond(xs) { 
      margin-top: 2.5rem;
    }
    @include respond(sm) { 
      margin-top: 2.5rem;
    }
    @include respond(md) { 
      margin-top: 2.5rem;
    }
    @include respond(lg) { 
      margin-top: 2.5rem; 
    }
    @include respond(xl) { 
    }
  }

  &-final-content {
    margin-bottom: 4rem;
  }
}
